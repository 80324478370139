<template>
    <el-form-item label="单据号">
        <el-input placeholder="请填写" v-model="code" style="width: 215px" />
    </el-form-item>
</template>

<script>
export default {
    name: 'EfBizCode',
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        value: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            code: '',
        };
    },
    watch: {
        value: {
            handler(n, o) {
                this.code = n;
            },
            immediate: true,
        },
        code: {
            handler(n, o) {
                this.$emit('input', n);
            },
            immediate: true,
        },
    },
};
</script>
